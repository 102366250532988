import request from '../utils/request'
import baseUrl from './baseUrl'
//打卡地列表
export const clockList = (data) => request({
    url: baseUrl + '/clockPlace/selectForBack',
    method: 'POST',
    data
})
//删除打卡地
export const removeClock = (data) => request({
    url: baseUrl + '/clockPlace/deleteById',
    method: 'POST',
    data
})
//添加打卡地
export const addClock = (data) => request({
    url: baseUrl + '/clockPlace/add',
    method: 'POST',
    data
})
//游戏线路列表
export const gameLineListAPI = (data) => request({
    url: baseUrl + '/clockLine/selectForBack',
    method: 'POST',
    data
})
//添加/修改线路
export const addLineAPI = (data) => request({
    url: baseUrl + '/clockLine/add',
    method: 'POST',
    data
})
//启用/禁用/删除线路
export const manageLineAPI = (data) => request({
    url: baseUrl + '/clockLine/modify',
    method: 'POST',
    data
})
//查询线路详情
export const lineDetailAPI = (data) => request({
    url: baseUrl + '/clockLine/selectForId',
    method: 'POST',
    data
})
//游戏路线列表
export const theGameLineAPI = (data) => request({
    url: baseUrl + '/clockLineInfo/selectForBack',
    method: 'POST',
    data
})
//查询可设置的打卡地
export const canUseCheckPointAPI = (data) => request({
    url: baseUrl + '/clockLineInfo/selectPlaceByLineId',
    method: 'POST',
    data
})
//添加打卡地
export const addCheckPointAPI = (data) => request({
    url: baseUrl + '/clockLineInfo/add',
    method: 'POST',
    data
})
//删除打卡地
export const removeCheckPointAPI = (data) => request({
    url: baseUrl + '/clockLineInfo/deleteById',
    method: 'POST',
    data
})
//生成打卡二维码
export const generateQRcodeAPI = (data) => request({
    url: baseUrl + '/clockLineInfo/selectErCode',
    method: 'POST',
    data
})
//线路排序
export const lineDeptAPI = (data) => request({
    url: baseUrl + '/clockLineInfo/modify',
    method: 'POST',
    data
})
